import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  List,
} from 'commons/components'

import { useParams } from 'react-router-dom'
import LaporanTable from '../components/LaporanTable'
import getFinancialPositionListElement from '../services/getFinancialPositionListElement'
import exportFinancialPosition from '../services/exportFinancialPosition'

const LaporanPosisiKeuanganPage = props => {
  const { checkPermission } = useAuth()
  const [financialPosition, setFinancialPosition] = useState()
  const {} = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: financialPositionListElement,
      } = await getFinancialPositionListElement({})
      setFinancialPosition(financialPositionListElement.data)
    }
    fetchData()
  }, [])

  const navigate = useNavigate()
  const download = async () => {
    await exportFinancialPosition()
    navigate('/financial-position')
  }
  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0 text-center">Laporan Posisi Keuangan</h2>
        <div className="not-prose flex flex-col sm:flex-row justify-center items-center sm:gap-4">
          <Button className="mt-2 sm:mt-0" onClick={download}>
            Download
          </Button>
        </div>
      </div>
      <div className="not-prose">
        <TableView {...{ financialPosition }} />
      </div>
    </div>
  )
}

const TableView = ({ financialPosition }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body p-0 sm:p-8 border sm:border-none">
        <Table compact>
          {financialPosition && (
            <LaporanTable laporanItem={financialPosition} />
          )}
        </Table>
      </div>
    </div>
  )
}

export default LaporanPosisiKeuanganPage
