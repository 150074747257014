import React, { useRef } from 'react'
import { TableBody, TableHead, TableRow, TableCell } from 'commons/components'

const LaporanTable = ({ laporanItem }) => {
  const DISTINCT_ROW = [
    'Aktivitas Operasional',
    'Aktivitas Investasi',
    'Aktivitas Pendanaan',
    '',
  ]

  const tableBody = useRef([])
  return laporanItem.map((row, index) => {
    if (DISTINCT_ROW.includes(row.name) || row.level == 0) {
      const tBody = [...tableBody.current]
      tableBody.current = []
      return (
        <>
          {tBody.length !== 0 && (
            <TableBody>
              {tBody.map((laporan, idx) => (
                <TableRow key={idx}>
                  <TableCell className="whitespace-normal max-w-[32ch]">
                    <span
                      style={{ paddingLeft: `${parseInt(laporan.level)}rem` }}
                    >
                      {laporan?.name}
                    </span>
                  </TableCell>
                  <TableCell isCurrency>{laporan?.amountLastYear}</TableCell>
                  <TableCell isCurrency>
                    {laporan?.amountTwoYearsBefore}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
          <TableHead>
            <TableRow>
              <TableCell className="whitespace-normal max-w-[32ch]">
                <span style={{ paddingLeft: `${parseInt(row.level)}rem` }}>
                  {row?.name}
                </span>
              </TableCell>
              <TableCell>{row?.amountLastYear}</TableCell>
              <TableCell>{row?.amountTwoYearsBefore}</TableCell>
            </TableRow>
          </TableHead>
        </>
      )
    } else {
      tableBody.current.push(row)
      if (index === laporanItem.length - 1)
        return (
          tableBody.current.length !== 0 && (
            <TableBody>
              {tableBody.current.map((laporanItem, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    <span
                      style={{
                        paddingLeft: `${parseInt(laporanItem.level)}rem`,
                      }}
                    >
                      {laporanItem?.name}
                    </span>
                  </TableCell>
                  <TableCell isCurrency>
                    {laporanItem?.amountLastYear}
                  </TableCell>
                  <TableCell isCurrency>
                    {laporanItem?.amountTwoYearsBefore}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )
        )
    }
  })
}

export default LaporanTable
