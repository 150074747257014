import activityRoutes from 'activity/routes'

import incomeRoutes from 'income/routes'

import expenseRoutes from 'expense/routes'

import arusKasReportRoutes from 'arusKasReport/routes'

import financialPositionRoutes from 'financialPosition/routes'

import activityReportRoutes from 'activityReport/routes'

import confirmationRoutes from 'confirmation/routes'

import pGDisbursementRoutes from 'pGDisbursement/routes'

import pGDonationRoutes from 'pGDonation/routes'
import paymentLinkRoutes from 'paymentLink/routes'

import { useRoutes } from 'react-router-dom'
import commonRoutes from 'commons/routes.js'
import userRoutes from 'user/routes'
import roleRoutes from 'role/routes'
import staticPageRoutes from 'staticPage/routes'

const GlobalRoutes = () => {
  const router = useRoutes([
    ...commonRoutes,
    ...staticPageRoutes,
    ...userRoutes,
    ...roleRoutes,

    ...activityRoutes,
    ...incomeRoutes,
    ...expenseRoutes,
    ...arusKasReportRoutes,
    ...financialPositionRoutes,
    ...activityReportRoutes,
    ...confirmationRoutes,
    ...pGDisbursementRoutes,

    ...pGDonationRoutes,
    ...paymentLinkRoutes,
  ])
  return router
}

export default GlobalRoutes
