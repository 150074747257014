import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, TableRow, TableCell, Modal } from 'commons/components'

const DaftarTable = ({ daftarItem }) => {
  const { checkPermission } = useAuth()

  return (
    <TableRow distinct={false}>
      {/* Data Binding Daftar Table Element*/}
      <TableCell>{daftarItem?.id}</TableCell>
      <TableCell>{daftarItem?.programName}</TableCell>
      <TableCell>{daftarItem?.coaName}</TableCell>
      <TableCell isCurrency>{daftarItem?.amount}</TableCell>
      <TableCell isHiddenMobile>{daftarItem?.product_name}</TableCell>
      <TableCell isHiddenMobile>{daftarItem?.status}</TableCell>
      <TableCell isHiddenMobile>
        <div class="btn-group gap-2">
          {/* View Element Event Daftar Table Element*/}
        </div>
      </TableCell>
    </TableRow>
  )
}

export default DaftarTable
