import { Button } from 'commons/components'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import KonfirmasiPaymentLink from '../components/KonfirmasiPaymentLink'
import getPaymentLinkData from '../services/getPaymentLinkData'

const KonfirmasiPaymentLinkPage = props => {
  const [data, setData] = useState()
  const { id } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const { data: paymentLinkData } = await getPaymentLinkData({ id })
      setData(paymentLinkData.data)
    }
    fetchData()
  }, [])

  return data ? (
    <div className="prose max-w-screen-lg mx-auto p-6">
      <h2>Konfirmasi Payment Link</h2>
      <KonfirmasiPaymentLink {...{ data }} />
    </div>
  ) : (
    <></>
  )
}

export default KonfirmasiPaymentLinkPage
