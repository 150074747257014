import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  List,
} from 'commons/components'

import DaftarTable from '../components/DaftarTable'
import getDisbursementListElement from '../services/getDisbursementListElement'

const TableDaftarDisbursementPage = props => {
  const { checkPermission } = useAuth()
  const [jsonAllDisbursement, setJsonAllDisbursement] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: disbursementListElement,
      } = await getDisbursementListElement()
      setJsonAllDisbursement(disbursementListElement.data)
    }
    fetchData()
  }, [])

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0 text-center">Table Daftar Disbursement</h2>
        <div className="not-prose flex flex-col sm:flex-row justify-center items-center sm:gap-4">
          <Link to="/donation/disbursement/create">
            <Button className="mt-2 sm:mt-0">Tambah Disbursement</Button>
          </Link>
        </div>
      </div>
      <div className="not-prose">
        <TableView {...{ jsonAllDisbursement }} />
      </div>
    </div>
  )
}

const TableView = ({ jsonAllDisbursement }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body p-0 sm:p-8 border sm:border-none">
        <Table className={'table-fixed sm:table-auto'}>
          <TableHead>
            <TableRow>
              <TableCell id="id" isHeading>
                Id
              </TableCell>
              <TableCell id="program" isHeading>
                Program
              </TableCell>
              <TableCell id="jenisPengeluaran" isHeading>
                Jenis Pengeluaran
              </TableCell>
              <TableCell id="jumlah" isHeading isCurrency>
                Jumlah
              </TableCell>
              <TableCell id="paymentGateway" isHeading isHiddenMobile>
                Payment Gateway
              </TableCell>
              <TableCell id="status" isHeading isHiddenMobile>
                Status
              </TableCell>
              <TableCell isHiddenMobile></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jsonAllDisbursement &&
              jsonAllDisbursement.map(daftarItem => (
                <DaftarTable key={daftarItem.id} daftarItem={daftarItem} />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default TableDaftarDisbursementPage
