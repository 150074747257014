import RequireAuth from 'commons/auth/RequireAuth'

import TambahDonasiPGPage from './containers/TambahDonasiPGPage'
import TableDonasiViaPaymentGatewayPage from './containers/TableDonasiViaPaymentGatewayPage'

const pGDonationRoutes = [
  {
    path: '/donation/paymentgateway/create',
    element: <TambahDonasiPGPage />,
  },
  {
    path: '/donation/paymentgateway/list',
    element: (
      <RequireAuth permissionNeeded="ReadPGDonation">
        <TableDonasiViaPaymentGatewayPage />
      </RequireAuth>
    ),
  },
]

export default pGDonationRoutes
