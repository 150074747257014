import { Button } from 'commons/components'
import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import getObjectPayment from '../services/getObjectPayment'

import FormPaymentLinkForm from '../components/FormPaymentLinkForm'

const PaymentLinkForm = props => {
  const { id } = useParams()

  const [objectPayment, setObjectPayment] = useState()

  useEffect(() => {
    const fetch = async () => {
      const { data: objectPaymentResponse } = await getObjectPayment({ id })

      setObjectPayment(objectPaymentResponse.data)
    }
    fetch()
  }, [])

  return objectPayment ? (
    <div>
      <FormPaymentLinkForm {...{ objectPayment }} {...props} />
    </div>
  ) : (
    <></>
  )
}

export default PaymentLinkForm
