import RequireAuth from 'commons/auth/RequireAuth'

import LaporanPosisiKeuanganPage from './containers/LaporanPosisiKeuanganPage'

const financialPositionRoutes = [
  {
    path: '/financial-position',
    element: <LaporanPosisiKeuanganPage />,
  },
]

export default financialPositionRoutes
