import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  List,
} from 'commons/components'

import { useParams } from 'react-router-dom'
import LaporanTable from '../components/LaporanTable'
import getActivityReportListElement from '../services/getActivityReportListElement'
import exportActivityReport from '../services/exportActivityReport'

const LaporanAktivitas = props => {
  const { checkPermission } = useAuth()
  const [activityReport, setActivityReport] = useState()
  const {} = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: activityReportListElement,
      } = await getActivityReportListElement({})
      setActivityReport(activityReportListElement.data)
    }
    fetchData()
  }, [])

  const navigate = useNavigate()
  const download = async () => {
    await exportActivityReport()
    navigate('/activity-report')
  }
  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0 text-center">Laporan Aktivitas</h2>
        <div className="not-prose flex flex-col sm:flex-row justify-center items-center sm:gap-4">
          <Button className="mt-2 sm:mt-0" onClick={download}>
            Download
          </Button>
        </div>
      </div>
      <div className="not-prose">
        <TableView {...{ activityReport }} />
      </div>
    </div>
  )
}

const TableView = ({ activityReport }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body p-0 sm:p-8 border sm:border-none">
        <Table compact>
          {activityReport && <LaporanTable laporanItem={activityReport} />}
        </Table>
      </div>
    </div>
  )
}

export default LaporanAktivitas
