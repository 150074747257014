import RequireAuth from 'commons/auth/RequireAuth'

import TambahDisbursementPage from './containers/TambahDisbursementPage'
import TableDaftarDisbursementPage from './containers/TableDaftarDisbursementPage'

const pGDisbursementRoutes = [
  {
    path: '/donation/disbursement/create',
    element: (
      <RequireAuth permissionNeeded="CreateDisbursement">
        <TambahDisbursementPage />
      </RequireAuth>
    ),
  },
  {
    path: '/donation/disbursement/list',
    element: <TableDaftarDisbursementPage />,
  },
]

export default pGDisbursementRoutes
