import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  MultiSelectField,
  TextAreaField,
  VisualizationAttr,
} from 'commons/components'
import {
  ALLOWED_PERMISSIONS,
  findAllowedPermission,
} from 'commons/constants/allowedPermission'
import cleanFormData from 'commons/utils/cleanFormData'

import savePGDisbursement from '../services/savePGDisbursement'

const FormTambahDisbursement = ({ programs, chartOfAccounts }) => {
  const { control, handleSubmit } = useForm()

  const navigate = useNavigate()

  const disburse = data => {
    const cleanData = cleanFormData(data)
    savePGDisbursement({
      ...cleanData,
    })
      .then(({ data: { data } }) => {
        navigate(`/donation/disbursement/list`)
      })
      .catch(error => {
        toast.error(
          error.response?.data?.data?.message ||
            error ||
            'Terjadi kesalahan pada sistem. Harap coba lagi!'
        )
      })
  }

  return (
    <Form title="Tambah Disbursement" onSubmit={handleSubmit(disburse)}>
      <Controller
        name="amount"
        control={control}
        rules={{ required: 'Harap masukkan jumlah' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Jumlah"
            placeholder="Masukkan jumlah"
            fieldState={fieldState}
            {...field}
            isRequired={true}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        rules={{ required: 'Harap masukkan deskripsi' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Deskripsi"
            placeholder="Masukkan deskripsi"
            fieldState={fieldState}
            {...field}
            isRequired={true}
          />
        )}
      />
      <Controller
        name="idProgram"
        control={control}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Program"
            options={programs}
            placeholder="Masukkan program"
            fieldState={fieldState}
            {...field}
            isRequired={false}
          />
        )}
      />
      <Controller
        name="idCoa"
        control={control}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Jenis Pengeluaran"
            options={chartOfAccounts}
            placeholder="Masukkan jenis pengeluaran"
            fieldState={fieldState}
            {...field}
            isRequired={false}
          />
        )}
      />
      <Controller
        name="paymentGateway"
        control={control}
        rules={{ required: 'Harap pilih payment gateway' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Payment Gateway"
            options={[
              { id: 'Midtrans', name: 'Midtrans' },
              { id: 'Flip', name: 'Flip' },
            ]}
            placeholder="Masukkan payment gateway"
            fieldState={fieldState}
            {...field}
            isRequired={true}
          />
        )}
      />
      <div className="card-actions justify-end">
        <Button type="submit" variant="primary">
          Disburse
        </Button>
      </div>
    </Form>
  )
}

export default FormTambahDisbursement
