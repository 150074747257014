import RequireAuth from 'commons/auth/RequireAuth'

import LaporanAktivitas from './containers/LaporanAktivitas'

const activityReportRoutes = [
  {
    path: '/activity-report',
    element: <LaporanAktivitas />,
  },
]

export default activityReportRoutes
