import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  List,
} from 'commons/components'

import DonasiTable from '../components/DonasiTable'
import getDonasiViaPaymentGatewayListElement from '../services/getDonasiViaPaymentGatewayListElement'

const TableDonasiViaPaymentGatewayPage = props => {
  const { checkPermission } = useAuth()
  const [jsonAllDonasiPG, setJsonAllDonasiPG] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: donasiViaPaymentGatewayListElement,
      } = await getDonasiViaPaymentGatewayListElement()
      setJsonAllDonasiPG(donasiViaPaymentGatewayListElement.data)
    }
    fetchData()
  }, [])

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0 text-center">Table Donasi Via Payment Gateway</h2>
      </div>
      <div className="not-prose">
        <TableView {...{ jsonAllDonasiPG }} />
      </div>
    </div>
  )
}

const TableView = ({ jsonAllDonasiPG }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body p-0 sm:p-8 border sm:border-none">
        <Table className={'table-fixed sm:table-auto'}>
          <TableHead>
            <TableRow>
              <TableCell id="id" isHeading>
                Id
              </TableCell>
              <TableCell id="nama" isHeading>
                Nama
              </TableCell>
              <TableCell id="program" isHeading>
                Program
              </TableCell>
              <TableCell id="jumlahDonasi" isHeading isCurrency>
                Jumlah Donasi
              </TableCell>
              <TableCell id="paymentGateway" isHeading isHiddenMobile>
                Payment Gateway
              </TableCell>
              <TableCell id="metodePembayaran" isHeading isHiddenMobile>
                Metode Pembayaran
              </TableCell>
              <TableCell id="status" isHeading isHiddenMobile>
                Status
              </TableCell>
              <TableCell isHiddenMobile></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jsonAllDonasiPG &&
              jsonAllDonasiPG.map(donasiItem => (
                <DonasiTable key={donasiItem.id} donasiItem={donasiItem} />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default TableDonasiViaPaymentGatewayPage
