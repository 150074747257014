import RequireAuth from 'commons/auth/RequireAuth'

import PaymentLinkForm from './containers/PaymentLinkForm'
import KonfirmasiPaymentLinkPage from './containers/KonfirmasiPaymentLinkPage'

const paymentLinkRoutes = [
  {
    path: '/paymentlink/confirmation/:id',
    element: <KonfirmasiPaymentLinkPage />,
  },
  {
    path: '/paymentlink/:id',
    element: <PaymentLinkForm />,
  },
]

export default paymentLinkRoutes
